/**
 * The Class Page
 */
.class-info {
    margin-bottom: 16px;
}


.class-video {
    border-radius: var(--border-radius);
    margin-bottom: 16px;
    overflow: hidden;
}
.class-video-content {
    max-width: 800px;
    margin: 0 auto;
}


.class-description h3 {
    margin: 0;
    color: var(--title-color);
    font-size: 20px;
    font-family: var(--title-font);
    line-height: 1.2;
    letter-spacing: 1px;
}
.class-description p {
    padding: 0 8px;
}


.class-details .details-list.details-list > li {
    cursor: pointer;
}



/**
 * Media Queries
 */
@media screen and (max-width: 700px) {
    .class-info .actions {
        display: none;
    }
    .class-video {
        padding: 0;
        margin-bottom: 32px;
    }
}
