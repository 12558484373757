/**
 * The Auth page
 */
.auth {
    position: relative;
    height: var(--full-height);
    overflow: auto;
    background-color: white;
    background-image: url("../../Images/AuthBG.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.auth::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(9, 30, 66, 0.2);
    z-index: 1;
}



/**
 * The Header
 */
.auth-header {
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: var(--auth-header);
    width: 1100px;
    max-width: 95%;
    margin: 0 auto;
    padding: 16px 0;
    color: white;
    z-index: 2;
}
.auth-header h1 {
    margin: 0;
    padding: 0 12px;
    color: rgba(255, 255, 255, 1);
    font-family: var(--title-font);
    font-weight: 200;
    font-size: 40px;
    line-height: 1;
}
.auth-header h1 img {
    max-height: 80px;
}



/**
 * The Content
 */
.auth-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(var(--full-height) - 2 * var(--auth-header));
    color: rgba(255, 255, 255, 0.8);
    z-index: 1;
}

.auth-title {
    margin: 0 0 10px 0;
    color: white;
    text-align: center;
    font-size: 24px;
    font-weight: 200;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 8px;
    border-radius: var(--border-radius);
    background-color: var(--primary-color);
}



/**
 * The Form
 */
.auth-form {
    box-sizing: border-box;
    width: calc(330px + 48px);
    max-width: 100%;
    padding: 24px;
}
.auth-form > h3 {
    margin: 0 0 10px 0;
    padding: 4px;
    text-align: center;
    font-size: 18px;
    color: var(--primary-color);
    font-weight: 400;
    border-radius: var(--border-radius);
    background-color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    transition: all 0.5s;
    text-overflow: ellipsis;
    overflow: hidden;
}
.auth-form > h3:hover {
    background-color: rgb(255, 255, 255);
}
.auth-form > p {
    margin: 10px 0;
    padding: 8px;
    color: black;
    text-align: center;
    font-size: 16px;
    border-radius: var(--border-radius);
    background-color: rgba(255, 255, 255, 0.7);
}
.auth-form .btn {
    font-size: 14px;
}

.auth-column {
    grid-gap: 0 10px;
}
.auth-field .text-field-error.text-field-error {
    color: white;
    margin: 0;
    padding: 4px;
    border-radius: var(--border-radius);
    background-color: var(--error-color);
}
.auth-hidden {
    display: none;
}

.auth-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
}
.auth-footer-btn {
    text-align: right;
    margin-top: 8px;
}
.auth-link {
    height: 30px;
    line-height: 30px;
    padding: 0 16px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: var(--border-radius);
}

.auth-check {
    padding: 6px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: var(--border-radius);
}
.auth-check label {
    background: transparent;
}

.auth-multiple {
    margin-bottom: 8px;
}
.auth-multiple .inputfield-input {
    border-color: var(--border-color);
}



/**
 * The Enroll
 */
.auth-enroll {
    position: relative;
    box-sizing: border-box;
    width: 800px;
    max-width: 95%;
    margin: 0 auto;
    z-index: 2;
}
.auth-enroll-single {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(var(--full-height) - 2 * var(--auth-header));
}

.auth-enroll-none.auth-enroll-none {
    padding: 16px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: var(--border-radius);
}
.auth-enroll-filters {
    padding: 16px;
    background-color: rgba(255, 255, 255, 0.7);
}
.auth-enroll-list {
    padding-bottom: 80px;
}
.auth-enroll-list > div {
    color: black;
    background-color: rgba(255, 255, 255, 0.7);
}
.auth-enroll-list h3 {
    color: var(--primary-color);
    font-size: 24px;
}



/**
 * Media Queries
 */
@media screen and (max-width: 600px) {
    .auth-header {
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;
    }
    .auth-container {
        display: block;
        height: calc(var(--full-height) - var(--auth-header));
    }
    .auth-form {
        margin: 0 auto;
    }
}

@media screen and (max-height: 820px) {
    .auth-container {
        display: block;
        height: calc(var(--full-height) - var(--auth-header));
    }
    .auth-form {
        margin: 0 auto;
    }
}

@media screen and (max-width: 350px) {
    .auth-footer {
        flex-direction: column;
    }
    .auth-footer .btn {
        order: 1;
        width: 100%;
    }
    .auth-link {
        box-sizing: border-box;
        order: 2;
        width: 100%;
        margin-top: 8px;
        text-align: center;
    }
}
