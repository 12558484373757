/**
 * The Dashboard
 */
.dashboard {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 360px;
    grid-column-gap: 32px;
    margin-bottom: 32px;
    padding-bottom: 0;
}


.dashboard-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px 16px;
}
.dashboard-title {
    margin: 0 0 8px 0;
    font-size: 20px;
    font-family: var(--title-font);
}
.dashboard-item {
    padding: 8px 16px;
    margin: 6px 0;
    background-color: var(--light-gray);
    color: var(--black-color);
    line-height: 1.4;
    --link-background: var(--dark-gray);
}
.dashboard-item .link-content {
    display: flex;
    align-items: center;
}
.dashboard-item i {
    flex-shrink: 0;
    display: block;
    width: 50px;
    color: var(--title-color);
    margin-right: 8px;
}
.dashboard-link {
    margin: 0 8px;
}


.dashboard-advice {
    border-radius: var(--border-radius);
    overflow: hidden;
}
.dashboard-advice img {
    display: block;
    width: 100%;
}



/**
 * Media Queries
 */
@media screen and (max-width: 1200px) {
    .dashboard {
        display: block;
    }
    .dashboard-advice {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .dashboard-content {
        grid-template-columns: 1fr;
    }
}
