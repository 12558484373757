/**
 * The Fonts Faces
 */
@font-face {
    font-family: "Lato";
    src: url("../../Fonts/Lato/Lato-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Lato";
    src: url("../../Fonts/Lato/Lato-Light.ttf");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Lato";
    src: url("../../Fonts/Lato/Lato-Bold.ttf");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Lato";
    src: url("../../Fonts/Lato/Lato-Italic.ttf");
    font-weight: normal;
    font-style: italic;
}


@font-face {
    font-family: "Lora";
    src: url("../../Fonts/Lora/Lora-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Cooper";
    src: url("../../Fonts/Cooper/Cooper-Light.ttf");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "icomoon";
    src:
        url("../../Fonts/Icomoon/icomoon.ttf?9i6t86") format("truetype"),
        url("../../Fonts/Icomoon/icomoon.woff?9i6t86") format("woff"),
        url("../../Fonts/Icomoon/icomoon.svg?9i6t86#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}



/**
 * The Main Styles
 */
html, body, #root {
    min-height: var(--full-height);
}

body {
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: var(--black-color);
    overflow: hidden;
}

a {
    color: var(--accent-color);
}
a:hover {
    text-decoration: none;
}

.no-list {
    margin: 0;
    padding: 0;
    list-style: none;
}
.spaced-list li:not(:last-child) {
    margin-bottom: 8px;
}

.big-text {
    font-size: 18px;
}

.spacing {
    padding: 24px;
}
.no-margin {
    margin: 0;
}

.right-space.right-space {
    margin-right: 8px;
}
.right-space-big.right-space-big {
    margin-right: 16px;
}

.left-space.left-space {
    margin-left: 8px;
}
.left-space-big.left-space-big {
    margin-left: 16px;
}

.bottom-space.bottom-space {
    margin-bottom: 8px;
}
.bottom-space-big.bottom-space-big {
    margin-bottom: 16px;
}

.align-center {
    text-align: center;
}

.two-columns {
    columns: 2;
    column-gap: 16px;
}
.two-columns > div {
    break-inside: avoid-column;
}



/**
 * Results
 */
.text-green.text-green {
    color: var(--success-color);
}
.text-yellow.text-yellow {
    color: var(--warning-color);
}
.text-red.text-red {
    color: var(--error-color);
}



/**
 * The Scrollbars
 */
.dark-scrollbars::-webkit-scrollbar,
.light-scrollbars::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}
.dark-scrollbars::-webkit-scrollbar,
.dark-scrollbars::-webkit-scrollbar-corner {
    background-color: var(--secondary-color);
}
.light-scrollbars::-webkit-scrollbar,
.light-scrollbars::-webkit-scrollbar-corner {
    background-color: var(--lighter-gray);
}
.dark-scrollbars::-webkit-scrollbar-thumb,
.dark-scrollbars::-webkit-scrollbar-thumb,
.light-scrollbars::-webkit-scrollbar-thumb,
.light-scrollbars::-webkit-scrollbar-thumb {
    border-radius: 999px;
    border: 2px solid transparent;
    border-left-width: 2px;
}
.dark-scrollbars::-webkit-scrollbar-thumb,
.dark-scrollbars::-webkit-scrollbar-thumb {
    box-shadow: 0 0 0 8px var(--primary-color) inset;
}
.light-scrollbars::-webkit-scrollbar-thumb,
.light-scrollbars::-webkit-scrollbar-thumb {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.1) inset;
}


.table-scrollbars::-webkit-scrollbar {
    width: 14px;
    height: 14px;
    border-left: 2px solid var(--light-gray);
}
.table-scrollbars::-webkit-scrollbar-thumb,
.table-scrollbars::-webkit-scrollbar-thumb {
    border-radius: 999px;
    border: 2px solid transparent;
    border-left-width: 4px;
    box-shadow: 0 0 0 8px var(--darker-gray) inset;
}


.dark-scrollbars:hover::-webkit-scrollbar-thumb:vertical,
.dark-scrollbars:focus::-webkit-scrollbar-thumb:vertical,
.light-scrollbars:hover::-webkit-scrollbar-thumb:vertical,
.light-scrollbars:focus::-webkit-scrollbar-thumb:vertical,
.table-scrollbars:hover::-webkit-scrollbar-thumb:vertical,
.table-scrollbars:focus::-webkit-scrollbar-thumb:vertical {
    min-height: 20px;
}
.dark-scrollbars:hover::-webkit-scrollbar-thumb:horizontal,
.dark-scrollbars:focus::-webkit-scrollbar-thumb:horizontal,
.light-scrollbars:hover::-webkit-scrollbar-thumb:horizontal,
.light-scrollbars:focus::-webkit-scrollbar-thumb:horizontal,
.table-scrollbars:hover::-webkit-scrollbar-thumb:horizontal,
.table-scrollbars:focus::-webkit-scrollbar-thumb:horizontal {
    min-width: 20px;
}



/**
 * The Font
 */
body,
input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="date"],
input[type="time"],
select,
textarea {
    font-family: var(--main-font);
}
