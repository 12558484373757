/**
 * The Navigation Header
 */
.navigation-header.navigation-header {
    padding: 12px 0 4px 12px;
}
.navigation-header img {
    display: block;
    height: calc(var(--navigation-size) + 8px);
}
