/**
 * The Help Page
 */
.help-container {
    display: flex;
    padding-top: 8px;
}

.help-sections {
    width: 200px;
    flex-shrink: 0;
    list-style: none;
    margin: 0 32px 0 0;
    padding: 0;
}
.help-sections a {
    padding: 8px 16px;
    font-size: 16px;
    border-right: 3px solid var(--light-gray);
}
.help-selected.help-selected {
    color: var(--primary-color);
    background-color: var(--light-gray);
    border-right: 3px solid var(--primary-color);
}

.help-content {
    flex-grow: 2;
}
.help-video {
    max-width: 700px;
    margin: 0 auto 32px;
}

.help-ticket {
    margin-top: 48px;
    text-align: center;
}
.help-ticket h3 {
    font-weight: 400;
}
