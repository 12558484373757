/**
 * The Strech Welcome
 */
.strech-header {
    display: grid;
    grid-gap: 8px;
    margin-bottom: 16px;
}
.strech-header-2 {
    grid-template-columns: repeat(2, 1fr);
}
.strech-header-3 {
    grid-template-columns: repeat(3, 1fr);
}


.strech-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px 16px;
    border-radius: var(--border-radius);
    background-color: var(--light-gray);
}
.strech-info .icon {
    font-size: 32px;
    margin-right: 8px;
}
.strech-info h3 {
    margin: 0;
    color: var(--title-color);
}
.strech-info p {
    margin: 8px 0 0 0;
}

.strech-guide {
    max-width: 800px;
    margin: 0 auto;
    overflow-x: auto;
    overflow-y: hidden;
}
.strech-pages {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: var(--border-radius);
    background-color: var(--light-gray);
}



/**
 * The Strech Certificate
 */
.certificate-title {
    margin-top: 8px;
    margin-bottom: 0;
    font-size: 20px;
    text-align: center;
    color: var(--primary-color);
}
.certificate-image {
    text-align: center;
}
.certificate-image img {
    max-width: 100%;
    max-height: calc(100vh - var(--header-height) - 85px - 32px);
}
.certificate-button.certificate-button {
    margin: 0 auto;
}



/**
 * Media Queries
 */
@media screen and (max-width: 500px) {
    .strech-header.strech-header {
        grid-template-columns: repeat(1, 1fr);
    }
}
