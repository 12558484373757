:root {
    /* Colors */
    /* --accent-color      : #967bb8;
    --primary-color     : #37376d;
    --secondary-color   : #2A2A5F;
    --border-color      : #1a1a3c; */

    --accent-color      : #967bb8;
    --primary-color     : #642f70;
    --secondary-color   : #4d2457;
    --border-color      : #1a1a3c;

    --title-color       : rgb(63, 87, 100);
    --subtitle-color    : rgb(94, 108, 132);
    --drop-color        : rgba(9, 30, 66, 0.5);

    --light-color       : #6e7379;
    --lighter-color     : #94979d;
    --lightest-color    : #b4b7bd;
    --white-color       : #f3f6f3;

    --black-color       : rgb(34, 34, 34);
    --lighter-gray      : rgb(244, 245, 247);
    --light-gray        : rgb(235, 236, 240);
    --dark-gray         : rgb(224, 225, 227);
    --darker-gray       : rgb(204, 205, 207);
    --darkerer-gray     : rgb(124, 125, 127);
    --darkest-gray      : rgb(101, 102, 103);
    --font-dark         : rgb(23, 43, 77);
    --font-light        : rgb(66, 82, 110);

    --error-color       : rgb(188, 28, 72);
    --success-color     : rgb(1, 163, 28);
    --warning-color     : rgb(240, 150, 9);

    --border-radius     : 4px;


    /* App Sizes */
    --full-height       : 100vh;
    --auth-header       : 112px;
    --topbar-height     : 56px;
    --sidebar-width     : 53px;
    --sidebar-top       : 4px;
    --sidebar-logo      : 46px;
    --navigation-width  : 220px;
    --navigation-size   : 50px;
    --details-width     : 280px;
    --drawer-width      : 360px;

    --header-height     : 76px;
    --mainheader-height : 92px;
    --tabs-table        : 32px;
    --alert-height      : 41px;
    --stats-height      : 60px;
    --filter-height     : calc(37px + 6px + 24px);

    --input-height      : 37px;
    --input-font        : 13px;
    --input-icon        : 16px;

    --inputicon-height  : 48px;
    --inputicon-move    : 10px;
    --inputicon-font    : 16px;
    --inputicon-icon    : 20px;

    --z-backdrop        : 1000;
    --z-details         : 1001;
    --z-sidebar         : 1002;
    --z-navigation      : 1003;
    --z-drawer          : 1004;
    --z-dialog          : 1005;
    --z-dropzone        : 1006;
    --z-result          : 1007;

    /* Fonts */
    --main-font         : "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
    --title-font        : "Cooper", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
