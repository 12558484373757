/**
 * The Calendar
 */
.calendar {
    width: 100%;
}


.calendar th {
    text-align: left;
    padding: 2px 4px;
    border: 1px solid white;
    height: 25px;
    color: var(--title-color);
    background: var(--light-gray);
    font-weight: bold;
    font-size: 12px;
    box-sizing: border-box;
}

.calendar-day th:first-child,
.calendar-week th:first-child {
    font-weight: bold;
    text-align: center;
}
.calendar-day th:first-child {
    width: 6%;
}
.calendar-days th:first-child {
    padding-top: 15px;
    text-align: center;
    font-size: 18px;
    color: #646464;
    border: 1px solid white;
    background-color: #e1e1e1;
    box-sizing: border-box;
}

.calendar-week th:nth-child(n+2) {
    min-width: 120px;
}
.calendar-week th:first-child {
    width: 5.5%;
}

.calendar-month th {
    min-width: 120px;
}
.calendar-month th:first-child {
    border-top-left-radius: var(--border-radius);
}
.calendar-month th:last-child {
    border-top-right-radius: var(--border-radius);
}



.calendar td {
    padding: 4px;
    vertical-align: top;
    color: #888888;
    box-shadow: inset 0 0 0 1px white;
    box-sizing: border-box;
    font-size: 12px;
}
.calendar-day td {
    width: 47%;
    height: 50px;
}
.calendar-week td {
    width: 13.5%;
    height: 50px;
}
.calendar-month td {
    width: 14.28%;
    height: 150px;
}


.calendar-name-number {
    padding: 0;
    font-size: 30px;
    font-weight: 100;
}
.calendar-name-day {
    padding: 0;
    margin-top: -5px;
}

.calendar a,
.calendar-number {
    color: #787878;
    display: block;
    margin-bottom: 5px;
}
.calendar-event {
    font-size: 13px;
}

.calendar-none {
    background-color: var(--light-gray);
}
.calendar-day {
    background-color: var(--lighter-gray);
}
.calendar-weekend {
    background-color: var(--dark-gray);
}
.calendar-today {
    background-color: var(--darker-gray);
}
