/**
 * Report Page
 */
.report-filter {
    padding: 12px;
    background-color: var(--lighter-gray);
    border-radius: var(--border-radius);
    margin-bottom: 16px;
    display: grid;
    grid-template-columns: repeat(3, 1fr) [last-col] 1fr;
    grid-gap: 8px;
}
.report-button {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    grid-column: last-col;
}

.report-canvas {
    height: 500px;
}
.report-table {
    margin-top: 32px;
}
