/**
 * Settings Page
 */
.settings-container {
    display: flex;
    max-width: 800px;
    padding-top: 16px;
}

.settings-content {
    flex-grow: 2;
    border-right: 1px solid var(--dark-gray);
}
.settings-form {
    box-sizing: border-box;
    padding: 0 32px 16px 16px;
    height: calc(var(--full-height) - var(--header-height) - 32px - 64px);
    overflow: auto;
}
.settings-subtitle {
    margin-top: 42px;
    font-weight: 400;
    font-family: var(--title-font);
    color: var(--title-color);
}
.settings-bottom {
    padding: 8px 8px 8px 16px;
    background-color: var(--lighter-gray);
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    text-align: right;
}



/**
 * The Avatar
 */
.settings-avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 40px;
}
.settings-input {
    display: none;
}
.settings-change.settings-change {
    margin-top: 16px;
    text-align: center;
}



/**
 * Media Queries
 */
@media screen and (max-width: 1000px) {
    .settings-container {
        max-width: none;
    }
    .settings-form {
        height: initial;
        overflow: initial;
    }
}

@media screen and (max-width: 700px) {
    .settings-container {
        flex-direction: column;
    }
    .settings-content {
        order: 2;
        border-right: none;
    }
    .settings-form {
        padding: 0 0 16px 0;
    }
    .settings-password {
        margin-top: 24px;
    }
    .settings-avatar {
        order: 1;
        padding-top: 0;
    }
}
